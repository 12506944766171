// The only 5 Content Groups for Google Analytics
// Use group mapping for consolidating
export const ATTORNEY_DIRECTORY = "attorneyDirectory";
export const ATTORNEY_PROFILE = "attorneyProfile";
export const AUTHOR_PROFILE = "authorProfile";
export const CITY_PAGE = "cityPage";
export const CONSUMER_REPORT = "consumerReport";
export const DONATIONS_PAGE = "donationsPage";
export const FRESH_START_DIARY = "freshStartDiary";
export const HOME_PAGE = "homePage";
export const LANDING_PAGE = "landingPage";
export const LEARN_ARTICLE = "learnArticle";
export const LEGAL_DEFINITION = "legalDefinition";
export const STATE_PAGE = "statePage";
export const BANKRUPTCY_PRODUCT_PAGE = 'bankruptcyProductPage'