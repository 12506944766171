import Link from "gatsby-link";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { A, ACSS, Button, H2, H6, P, Select } from "@upsolve/ui";
import { graphql } from "gatsby";
import { kebabCase, orderBy, startCase } from "lodash";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import LearnArticleTile from "../components/Learn/LearnArticleTile";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { learnTagsSortedByPriority } from "../components/Learn/learnTagPriority";

const contentGroup = CONTENT_GROUPS.LEARN_ARTICLE;
const templateProps = {
  pageComponentName: "LearnArticleTag",
  pageComponentVersion: 0,
};
const trackingPageContext = {
  ...templateProps,
  contentGroup,
  contentLocalized: false,
};

const LearnArticleTag = (props) => {
  const { articles, tag, tags } = props.data;
  // Get article filter tag off param (links to sub category setups)
  const [tagFilter, setTagFilter] = useState();
  useEffect(() => {
    if (typeof window !== undefined) {
      setTagFilter(new URLSearchParams(window.location.search).get("tagFilter") || "");
    }
  }, []);
  // Page Track
  useEffect(() => {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }, []);

  return (
    <TopLevelLayout>
      <SEO
        title={`${tag.label} | Upsolve`}
        description={tag.descriptionShort.descriptionShort}
        image="https://upsolve.org/images/meta-legalaid.png"
        url={`https://upsolve.org/learn/category/${kebabCase(tag.value)}/`}
        jsonLD={[
          {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@context": "http://schema.org",
                "@type": "ListItem",
                position: 1,
                name: "Learn",
                item: "https://upsolve.org/learn/",
              },
              {
                "@context": "http://schema.org",
                "@type": "ListItem",
                position: 2,
                name: tag.label,
                item: `https://upsolve.org/learn/category/${kebabCase(tag.value)}/`,
              },
            ],
          },
        ]}
      />
      <MainHeader trackingPageContext={trackingPageContext} />
      <LearnArticleTagPage>
        <section className="cover">
          <div className="blob" />
          <H2 as="h1">{tag.label}</H2>
          <P as="h4">{tag.descriptionShort.descriptionShort}</P>
          {tag.value !== "bankruptcyBasics" && (
            <div className="cover__new-cta">
              <Link to="/learn/category/bankruptcy-basics/">
                <Button>New to Bankruptcy? Start Here</Button>
              </Link>
            </div>
          )}
        </section>
        <section className="main">
          <div className="content">
            <div className="content__breadcrumbs">
              <ul>
                <li>
                  <Link to="/learn/">Learning Center</Link>
                </li>
                <li>
                  <b>{tag.label}</b>
                </li>
              </ul>
            </div>
            {tag.descriptionLong && tag.descriptionLong.descriptionLong && (
              <div className="content__description">
                <P>{tag.descriptionLong.descriptionLong || ""}</P>
                <P>
                  <i>{tag.descriptionForWho.descriptionForWho || ""}</i>
                </P>
              </div>
            )}
            <div className="content__filter">
              <P>
                <b>Filter by content also tagged as:</b>
              </P>
              <Select value={tagFilter} onChange={(ev) => setTagFilter(ev.target.value)}>
                <option value="">All Tags</option>
                {Array.from(new Set(articles.nodes.map((n) => n.tags.map((t) => t.value)).flat()))
                  .filter((tagValue) => tagValue !== tag.value)
                  .sort()
                  .map((tag) => (
                    <option key={tag} value={tag}>
                      {startCase(tag)}
                    </option>
                  ))}
              </Select>
            </div>
            <div className="content__articles">
              {articles.nodes
                .filter((article) => (!tagFilter ? true : article.tags.some((tag) => tag.value === tagFilter)))
                .map((article) => (
                  <LearnArticleTile key={article.slug} article={article} />
                ))}
            </div>
          </div>
          <aside>
            {tag.fundamentalLearnArticles && (
              <>
                <H6>Fundamentals</H6>
                <ul>
                  {tag.fundamentalLearnArticles.map((article) => (
                    <li key={article.slug}>
                      <A href={`/learn/${article.slug}/`}>{article.title}</A>
                    </li>
                  ))}
                </ul>
              </>
            )}
            <H6>Related Resources</H6>
            <ul>
              {orderBy(tags.nodes, [(t) => Object.values(learnTagsSortedByPriority).indexOf(t.value)], ["asc"])
                .filter((node) => node.hasDirectoryPage)
                .map((tag) => (
                  <li key={kebabCase(tag.value)}>
                    <Link to={`/learn/category/${kebabCase(tag.value)}/`}>{tag.label}</Link>
                  </li>
                ))}
            </ul>
          </aside>
        </section>
      </LearnArticleTagPage>
      <MainFooter />
    </TopLevelLayout>
  );
};

const LearnArticleTagPage = styled.article`
  section.cover {
    width: 100%;
    padding: 4em 0;
    text-align: center;
    background: ${(props) => props.theme.colors.white[800]};
    h1 {
      margin-bottom: 0;
    }
    & > * {
      margin: 0 auto 1em;
      max-width: 980px;
    }
    .cover__new-cta a,
    .cover__new-cta button {
      display: flex;
      max-width: 320px;
      margin: 0 auto;
    }
    @media (max-width: 45em) {
      padding: 4em 2em;
    }
  }
  section.main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4em 0;
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: 45em) {
      flex-direction: column;
    }
  }
  div.content {
    & > div {
      margin: 0 2em 2em;
      @media (max-width: 45em) {
        margin: 0 1em 2em;
      }
    }
  }
  div.content__breadcrumbs {
    ul {
      display: flex;
      margin: 0;
      padding-bottom: 0.5em;
      border-bottom: 1px solid ${(props) => props.theme.colors.white[300]};
    }
    li a {
      ${ACSS}
    }
    li:not(:first-of-type) {
      margin-left: 0.5em;
      &::before {
        margin-right: 0.5em;
        content: "/";
        color: ${(props) => props.theme.colors.white[100]};
      }
    }
    @media (max-width: 45em) {
      ul {
        display: block;
      }
      li:not(:first-of-type) {
        margin-left: 0;
        &::before {
          margin-right: 0;
          display: none;
        }
      }
    }
  }
  div.content__description {
    p:first-of-type {
      font-weight: 500;
    }
  }
  div.content__filter {
    background: ${(props) => props.theme.colors.white[800]};
    padding: 1.25em;
    border-radius: 12px;
    & > * {
      margin: 0.25em 0;
    }
    .reset {
      &:hover {
        cursor: pointer;
      }
    }
  }
  div.content__articles {
    & > div {
      margin: 2em 0;
    }
  }
  aside {
    width: 220px;
    min-width: 220px;
    margin: 0 2em;
    h6 {
      color: ${(props) => props.theme.colors.gray[700]};
    }
    ul {
      li,
      a {
        display: block;
        margin-bottom: 0.5em;
      }
      a {
        ${ACSS}
      }
      .sub-category {
        li {
          display: flex;
          padding-left: 8px;
          &::before {
            content: "·";
            padding-right: 6px;
            color: ${(props) => props.theme.colors.gray[700]};
          }
        }
      }
    }
    @media (max-width: 45em) {
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 2em;
    }
  }
  .blob {
    text-align: center;
    width: 100%;
    height: 150px;
    background: url(${require("../../static/images/blob-debt-discharged.png")});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 45em) {
      margin-top: 0;
      height: 70px;
    }
  }
`;

export const pageQuery = graphql`
  query LearnArticleTagTemplate($tagValue: String) {
    tag: contentfulLearnArticleTag(value: { eq: $tagValue }) {
      value
      label
      descriptionShort {
        descriptionShort
      }
      descriptionLong {
        descriptionLong
      }
      descriptionForWho {
        descriptionForWho
      }
      hasDirectoryPage
      fundamentalLearnArticles {
        slug
        title
      }
    }
    tags: allContentfulLearnArticleTag(sort: { fields: [value] }) {
      nodes {
        value
        label
        type
        hasDirectoryPage
        childTags {
          value
          label
          type
        }
      }
    }
    articles: allContentfulLearnArticle(
      filter: { tags: { elemMatch: { value: { eq: $tagValue } } } }
      sort: { fields: [siteLinking___importanceRank, createdAt] }
    ) {
      nodes {
        createdAt
        updatedAt
        author {
          ...AuthorNode
        }
        authors {
          ...AuthorNode
        }
        slug
        title
        tags {
          value
          label
          type
          hasDirectoryPage
        }
        articleExcerpt {
          articleExcerpt
        }
      }
    }
  }
`;

export default LearnArticleTag;
